const Products = [
  {
    id: 'cbm',
    name: 'CBM',
    hasUnits: true,
    hasSetupCost: true
  },
  {
    id: 'mercashop',
    name: 'Mercashop',
    hasUnits: true,
    hasSetupCost: true
  },
  {
    id: 'mercapromo',
    name: 'Mercapromo',
    hasUnits: true,
    hasSetupCost: false
  },
  {
    id: 'promotrade',
    name: 'Promotrade',
    hasUnits: true,
    hasSetupCost: false
  },
  {
    id: 'app',
    name: 'Aplicativo',
    hasUnits: false,
    hasSetupCost: false
  },
  {
    id: 'email',
    name: 'E-mail',
    hasUnits: false,
    hasSetupCost: false
  },
  {
    id: 'sms',
    name: 'SMS',
    hasUnits: false,
    hasSetupCost: false
  },
  {
    id: 'whatsapp',
    name: 'Whatsapp',
    hasUnits: false,
    hasSetupCost: false
  },
  {
    id: 'mercalider',
    name: 'MercaLíder',
    hasUnits: false,
    hasSetupCost: false
  },
  {
    id: 'headcount',
    name: 'Recurso Dedicado',
    hasUnits: false,
    hasSetupCost: false
  },
  {
    id: 'infrastructure',
    name: 'Infraestrutura',
    hasUnits: false,
    hasSetupCost: false
  },
  {
    id: 'customization',
    name: 'Customização',
    hasUnits: false,
    hasSetupCost: false
  }
]

const ProductsById = Products.reduce((obj, product) => ({ ...obj, [product.id]: product }), {})

module.exports = {
  Products,
  ProductsById
}
