module.exports = {
  appPreSets: {
    facebookBundle: 'fb326410019008941',
    apiCenterToken: '3f4ea6d8246359fdcbd2ddd9c0c463040237713e',
    webClientId: '939456025008-kq6c8v018mojjh6c2p5a27ksmi7jaqim',
    metadata: {
      ios: {
        description:
          'Com o aplicativo do Clube Mercafacil você tem acesso aos descontos exclusivos do Clube onde estiver. E ainda pode adicionar os seus itens preferidos a uma lista de compras ou criar uma lista de itens favoritos para acompanhar se entram em oferta.\n\nTudo o que você precisa para comprar melhor\nDescubra as melhores promoções, separadas com exclusividade para você;\nCrie suas listas de compras de um jeito rápido e fácil;\nAdicione seus produtos preferidos a uma lista de favoritos;\nVeja no mapa o endereço da loja mais próxima, além de informações de contato, horário de funcionamento e atendimento ao consumidor;\nTenha acesso aos canais digitais do seu supermercado.\n\n\nBaixe agora e tenha uma nova experiência de compra!',
        keywords: 'Supermercado,Ofertas,Clube,Desconto,Mercafacil',
        marketing_url: 'https://www.mercafacil.com/',
        name: 'Clube Mercafacil',
        privacy_url: 'https://regulamentos.mercafacil.com/mercafacil_politica_privacidade.html',
        promotional_text: 'Interaja com as promoções, novidades e informações do Clube Mercafacil.\n\nTudo para transformar sua experiência de compra.',
        release_notes: 'Refizemos nosso login e cadastro e alguns ajustes no visual das ofertas para melhorar a experiencia dos usuários.',
        support_url: 'https://www.mercafacil.com/'
      },
      android: {
        full_description:
          'Com o aplicativo do Clube Mercafacil você tem acesso aos descontos exclusivos do Clube onde estiver. E ainda pode adicionar os seus itens preferidos a uma lista de compras ou criar uma lista de itens favoritos para acompanhar se entram em oferta.\n\nTudo o que você precisa para comprar melhor\nDescubra as melhores promoções, separadas com exclusividade para você;\nCrie suas listas de compras de um jeito rápido e fácil;\nAdicione seus produtos preferidos a uma lista de favoritos;\nVeja no mapa o endereço da loja mais próxima, além de informações de contato, horário de funcionamento e atendimento ao consumidor;\nTenha acesso aos canais digitais do seu supermercado.\n\n\nBaixe agora e tenha uma nova experiência de compra!',
        short_description: 'Interaja com as promoções, novidades e informações do Clube Mercafacil.',
        title: 'Clube Mercafacil'
      }
    }
  }
}
