<template>
  <v-dialog v-model="dialog" :max-width="maxWidth" :persistent="persistent">
    <v-card>
      <v-container fluid>
        <v-row v-if="title" no-gutters>
          <v-col>
            <v-card-title :color="headerColor">
              <div v-html="title"></div>
            </v-card-title>
          </v-col>
        </v-row>
        <slot></slot>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
/**
 * Dialog padrão do projeto
 * @component
 */
export default {
  props: {
    /**
     * Indica se o botão de fechar a modal vai aparecer
     */
    useIconClose: {
      type: Boolean,
      default: false
    },
    /**
     * @model
     */
    value: {
      type: Boolean,
      default: false
    },
    /**
     * Título do botão
     */
    title: {
      type: String,
      default: ''
    },
    /**
     * Cor de background do header da modal
     */
    headerColor: {
      type: String,
      default: 'primary'
    },
    /**
     * Valor maximo de width
     */
    maxWidth: {
      type: [String, Number],
      default: '600'
    },
    /**
     * Indica se o modal será fechado ao clicar na parte extern
     */
    persistent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: this.value
    }
  },
  watch: {
    value() {
      this.dialog = this.value
    },
    dialog() {
      /**
       * Atualiza a model
       * @event input
       */
      this.$emit('input', this.dialog)
    }
  },
  methods: {
    /**
     * Funão para fechar a modal
     */
    closeDialog() {
      /**
       * Fecha o dialog
       * @event closeDialog
       */
      this.$emit('closeDialog', this.dialog)
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  max-height: 100% !important;
}
</style>
