export const headers = {
  computed: {
    computedHeaders() {
      const sizeWithoutCodeAndProduct = 100 - (24 + 8.5)
      const headers = this.headers.filter(header => header.isActive)
      const activableHeadersSize = headers.filter(header => !header.isRequired).length
      const result = headers.map(header => {
        return {
          ...header,
          width: header.width ? header.width : `${sizeWithoutCodeAndProduct / activableHeadersSize}%`
        }
      })
      return result
    }
  }
}
