const RetailSegments = [
  {
    value: '-',
    text: 'Nenhum Segmento'
  },
  {
    text: 'Varejo/Atacado',
    value: 1
  },
  {
    text: 'Utilidades',
    value: 2
  },
  {
    text: 'Domesticas/Variedades',
    value: 3
  },
  {
    text: 'Supermercado',
    value: 4
  },
  {
    text: 'Produto de limpeza',
    value: 5
  },
  {
    text: 'Petshop',
    value: 6
  },
  {
    text: 'Padaria',
    value: 7
  },
  {
    text: 'Material de Construção',
    value: 8
  },
  {
    text: 'Restaurante',
    value: 9
  },
  {
    text: 'Maquinário',
    value: 10
  },
  {
    text: 'Loja de Produtos Naturais',
    value: 11
  },
  {
    text: 'Hortifruti',
    value: 13
  },
  {
    text: 'Cosmética',
    value: 14
  },
  {
    text: 'Casa de carnes',
    value: 15
  },
  {
    text: 'Cama mesa e banho',
    value: 16
  },
  {
    text: 'Atacado',
    value: 17
  },
  {
    text: 'Varejo',
    value: 18
  },
  {
    text: 'Escritório',
    value: 19
  },
  {
    text: 'Loja de Suplementos',
    value: 20
  },
  {
    text: 'Matcon',
    value: 21
  },
  {
    text: 'Enfermagem',
    value: 22
  },
  {
    text: 'Farmácia',
    value: 23
  },
  {
    text: 'Peixaria',
    value: 24
  },
  {
    text: 'Transporte',
    value: 25
  },
  {
    text: 'Informática',
    value: 26
  },
  {
    text: 'Casa de ração',
    value: 27
  },
  {
    text: 'Postos de gasolina',
    value: 29
  },
  {
    text: 'Lojas de Conveniências',
    value: 30
  },
  {
    text: 'Distribuidora de Bebidas',
    value: 31
  },
  {
    text: 'Confeitaria',
    value: 32
  }
]

module.exports = {
  RetailSegments
}
