module.exports = {
  tableHeaders: {
    avaliableProducts: [
      {
        text: 'ID Produto',
        value: '_id',
        align: 'center',
        width: '7%',
        isActive: true
      },
      {
        text: 'Produto',
        value: 'product_type',
        align: 'center',
        width: '7%',
        isActive: true
      },
      {
        text: 'Status',
        value: 'active',
        align: 'center',
        width: '7%',
        isActive: true
      },
      {
        text: 'Abono Temporário',
        value: 'temporary_churn',
        align: 'center',
        width: '7%',
        isActive: true
      },
      {
        text: 'Total MRR',
        value: 'total_mrr',
        align: 'center',
        width: '7%',
        isActive: true
      },
      {
        text: 'Valor Unitário',
        value: 'unit_value',
        align: 'center',
        width: '7%',
        isActive: true
      },
      {
        text: 'Quantidade Adquirida',
        value: 'slots',
        align: 'center',
        width: '7%',
        isActive: true
      },
      {
        text: 'Data de Venda',
        value: 'created_at',
        width: '10%',
        align: 'center'
      },
      {
        text: 'Data de Criação',
        value: 'updated_at',
        width: '10%',
        align: 'center'
      }
    ],
    new_sale: [
      {
        text: 'Nome',
        value: 'name',
        align: 'center',
        isActive: true
      },
      {
        text: 'Valor Unitário',
        value: 'unit_value',
        align: 'center',
        isActive: true
      },
      {
        text: 'Quantidade',
        value: 'quantity',
        align: 'center',
        isActive: true
      },
      {
        text: 'Faturamento Imediato',
        value: 'immediate_billing',
        align: 'center',
        isActive: false
      },
      {
        text: 'Data de Faturamento',
        value: 'billing_date_periods',
        align: 'center',
        isActive: true
      },
      {
        text: 'Valor Total',
        value: 'total_value',
        align: 'center',
        isActive: true
      },
      {
        text: 'Lojas',
        value: 'units',
        align: 'center',
        isActive: true
      },
      {
        text: 'Tipo de Cobrança',
        value: 'charge_type',
        align: 'center'
      },
      {
        text: 'Acumulativo',
        value: 'is_accumulative',
        align: 'center'
      },
      {
        text: 'Valor Total Antigo',
        value: 'old_total_value',
        align: 'center'
      },
      {
        text: 'Valor Unitário Antigo',
        value: 'old_unit_value',
        align: 'center'
      },
      {
        text: 'Diferença do Valor Total',
        value: 'discount_operation_value',
        align: 'center'
      },
      {
        text: 'Plano',
        value: 'plan',
        align: 'center'
      },
      {
        text: 'Valor da Implantação',
        value: 'implantation_value',
        align: 'center'
      },
      {
        text: 'Ambev - Meses',
        value: 'ambev_incentive_months',
        align: 'center'
      },
      {
        text: 'Ambev - Texto',
        value: 'ambev_incentive_text',
        align: 'center'
      }
    ],
    temporary_churn: [
      {
        text: 'Produto',
        value: 'type',
        align: 'center'
      },
      {
        text: 'Valor Unitário Abonado',
        value: 'unit_value',
        align: 'center'
      },
      {
        text: 'Quantidade Abonada',
        value: 'quantity',
        align: 'center'
      },
      {
        text: 'Total Abonado',
        value: 'total_value',
        align: 'center'
      }
    ],
    revert_temporary_churn: [
      {
        text: 'Nome Produto',
        value: 'name',
        align: 'center'
      },
      {
        text: 'Valor Unitário Abonado',
        value: 'unit_value',
        align: 'center'
      },
      {
        text: 'Quantidade Abonada',
        value: 'quantity',
        align: 'center'
      },
      {
        text: 'Total Abonado',
        value: 'total_value',
        align: 'center'
      }
    ],
    downsell: [
      {
        text: 'Id da venda de origem',
        value: 'sale_id',
        align: 'center'
      },
      {
        text: 'Nome do produto',
        value: 'type'
      },
      {
        text: 'Quantidade',
        value: 'quantity'
      },
      {
        text: 'Incentivo Ambev',
        value: 'ambev_incentive',
        align: 'center'
      }
    ]
  }
}
