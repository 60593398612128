const getAccountStatus = status => {
  if (status?.deploying)
    return {
      label: 'Implantando',
      color: '#339af0'
    }
  if (status?.done)
    return {
      label: 'Concluído',
      color: '#20c997'
    }
  if (status?.churn)
    return {
      label: 'Churn',
      color: '#ff6b6b'
    }
  if (status?.impossible_support)
    return {
      label: 'Suporte Impossível',
      color: '#adb5bd'
    }
  if (status?.test)
    return {
      label: 'Teste',
      color: '#339af0'
    }
  if (status?.temporary_churn)
    return {
      label: 'Churn Temporário',
      color: '#FFF176'
    }
  if (status?.adjusting)
    return {
      label: 'Em ajuste',
      color: '#339af0'
    }
  if (status?.deprecated)
    return {
      label: 'Plataforma Antiga',
      color: '#adb5bd'
    }
  if (status?.waiting_release)
    return {
      label: 'Aguardando lançamento',
      color: '#f0b433'
    }
  else
    return {
      label: 'Sem Status',
      color: '#adb5bd'
    }
}

module.exports = { getAccountStatus }
