import { ProductsById } from '@/constants'
export const productsTotalValue = {
  watch: {
    product: {
      handler() {
        this.$emit('input', { ...this.product, total_value: this.totalValue })
      },
      deep: true
    }
  }
}

export const productsUnitValue = {
  watch: {
    product: {
      handler() {
        this.$emit('input', { ...this.product, unit_value: this.unitValue })
      },
      deep: true
    }
  }
}

export const productsInformation = {
  methods: {
    getProductFromType(productType) {
      return ProductsById[productType] || {}
    }
  }
}
