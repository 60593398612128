module.exports = {
  PAGES: [
    'home',
    'clube/desempenho',
    'clube/dashboard',
    'clube/comparativo',
    'clube/lojas',
    'maria/quando-vou-vender',
    'clientes/segmentacao',
    'clientes/perfil_demografico',
    'clientes/mapa',
    'clientes/retencao',
    'clientes/interesses/genero',
    'clientes/interesses/idade',
    'clientes/interesses/pessoa_juridica',
    'clientes/melhores_clientes',
    'clientes/busca_cliente',
    'clientes/detalhes_cliente',
    'produtos/busca_produto',
    'produtos/ranking_produtos',
    'produtos/comparativo_produtos',
    'produtos/detalhes_produto',
    'produtos/gerenciamento_produtos',
    'produtos/faixas_precos',
    'campanhas/calendario_promocional',
    'campanhas/resultados_gerais',
    'campanhas/whatsapp',
    'campanhas/sms',
    'campanhas/email',
    'campanhas/resultados_encarte',
    'industria/ofertas_trade',
    'industria/ofertas_trade/resultados_franquia'
  ],
  MODULES: [
    'push',
    'operational_performance',
    'products_tree',
    'chatbot',
    'offer_management',
    'movidesk',
    'whatsapp',
    'hotsite',
    'telesales',
    'mobile',
    'email_v2',
    'points',
    'uncategorized_products',
    'forecast',
    'cashback',
    'industry',
    'dynamic_sales'
  ]
}
