export const modules = {
  push: false,
  operational_performance: false,
  products_tree: false,
  chatbot: false,
  offer_management: false,
  movidesk: false,
  whatsapp: false,
  hotsite: false,
  telesales: false,
  mobile: false,
  email_v2: false,
  points: false,
  cashback: false,
  uncategorized_products: false,
  forecast: false,
  advanced_discounts: false,
  raffle: false,
  industry: false,
  dynamic_sales: true
}
