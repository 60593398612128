<template>
  <v-menu
    v-model="menu"
    class="date-picker"
    :close-on-content-click="false"
    :disabled="disabled"
    transition="scale-transition"
    offset-y
    :nudge-right="40"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-icon v-if="hideInput" id="date-picker-icon" v-model="formattedDate" v-on="on"> keyboard_arrow_down </v-icon>
      <v-text-field
        v-else
        v-model="formattedDate"
        :class="classProps"
        :solo="solo"
        :rules="rules"
        :error-messages="errorMessages"
        :outlined="outline"
        :disabled="disabled"
        :hide-details="hideDetails"
        :label="label"
        readonly
        :single-line="singleLine"
        :prepend-inner-icon="iconName"
        append-icon="calendar_today"
        v-on="!readonly ? on : ''"
      />
    </template>
    <v-date-picker v-if="!readonly" v-model="computedDate" :type="type" locale="pt-BR" :max="max" :min="min" :allowed-dates="allowedDates" @input="input" />
  </v-menu>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    label: String,
    date: String,
    singleLine: Boolean,
    max: String,
    min: String,
    icon: Boolean,
    outline: Boolean,
    hideDetails: Boolean,
    solo: Boolean,
    disabled: Boolean,
    errorMessages: [Array, String],
    type: String,
    hideInput: Boolean,
    classProps: {
      type: String,
      default: 'px-0'
    },
    allowedDates: {
      type: Function
    },
    readonly: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    menu: false
  }),
  computed: {
    computedDate: {
      get() {
        if (!this.date) return null
        return this.date.split('T')[0]
      },
      set(value) {
        this.$emit('update:date', value)
      }
    },
    formattedDate() {
      return this.formatDate(this.date)
    },
    iconName() {
      if (this.icon) return 'event'
      return ''
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return null
      if (this.type === 'month') {
        return _.capitalize(this.$moment(date, 'YYYY-MM').format('MMMM [de] YYYY'))
      }
      return this.$moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },
    input(value) {
      this.$emit('input', value)
      this.menu = false
    }
  }
}
</script>
<style lang="scss">
.date-picker {
  .v-text-field__details {
    margin: 0 !important;
  }
}
#date-picker-icon {
  height: 24px;
}
.v-text-field__details {
  padding-left: 0 !important;
}
</style>
