<template>
  <base-dialog v-model="dialog" persistent :title="title" max-width="800">
    <v-row class="mx-1">
      <v-col cols="12">
        <v-row no-gutters justify="end">
          <v-col cols="auto">
            <v-icon @click="startTour">info</v-icon>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-row id="first-section">
              <v-col cols="6">
                <mf-text-input v-model="retailerName" disabled label="Nome"></mf-text-input>
              </v-col>
              <v-col cols="6">
                <mf-text-input v-model="retailerCnpj" disabled label="CNPJ"></mf-text-input>
              </v-col>
            </v-row>
          </v-col>
          <v-col id="sixth-section" class="mt-n3" cols="6">
            <date-picker :date.sync="inactivated_at" :allowed-dates="allowedDates" :disabled="readonly" solo-inverted outline label="Data do churn" />
          </v-col>
          <v-col id="seven-section" class="mt-n3" cols="auto">
            <mf-toggle v-model="billed_client" label="Cliente faturado" :disabled="readonly" color="#334A58"></mf-toggle>
          </v-col>
        </v-row>
        <v-row v-if="readonly">
          <v-col id="second-section" cols="6">
            <mf-text-input disabled :value="translatePhase(dataChurn.phase)" label="Fase em que o clinte está" />
          </v-col>
          <v-col id="third-section" cols="6">
            <mf-text-input disabled :value="dataChurn.cause_details" label="Causa detalhada do churn" />
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col id="second-section" cols="6">
            <v-autocomplete v-model="phase_selected" :items="getAreas(true)" outlined label="Selecione a fase que o cliente está"></v-autocomplete>
          </v-col>
          <v-col id="third-section" cols="6">
            <v-autocomplete v-model="causeSelected" label="Selecione a Causa do Churn" :items="listCauses" outlined />
          </v-col>
        </v-row>
        <v-row>
          <v-col id="fourth-section" cols="6">
            <mf-text-input disabled :value="reason" label="Motivo do churn" />
          </v-col>
          <v-col id="fifth-section" cols="6">
            <mf-text-input v-if="readonly && isRetailer" disabled :value="translatePhase(dataChurn.responsible_area)" label="Área responsável pelo churn" />
            <mf-select
              v-else-if="isRetailer"
              id="fifth-section"
              v-model="responsible_area_selected"
              :items="getAreas()"
              outlined
              label="Selecione a área responsável pelo churn"
            ></mf-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col id="seventh-section">
            <mf-text-area-input v-model="detail_reason" :rules="text_area_rules" label="Detalhe o motivo que levou ao churn"></mf-text-area-input>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer />
          <mf-action-buttons
            class="mr-1"
            :primary-button="{
              text: 'Salvar churn',
              action: clickButton,
              isVisible: !readonly,
              isDisabled: disableButton
            }"
            :extra-button="{
              text: !readonly ? 'Cancelar' : 'Sair',
              action: closeDialog,
              isVisible: true
            }"
          />
        </v-row>
      </v-col>
    </v-row>
  </base-dialog>
</template>
<script>
import BaseDialog from '../atoms/BaseDialog.vue'
import DatePicker from '../../calendar/DatePicker.vue'
import { DateTime } from 'luxon'
import { allowedDateCrmActions } from '../../../helpers/crm'
import { churnReasons } from '@/mixin'
/**
 * Project default dialog
 * @component
 */
export default {
  components: {
    BaseDialog,
    DatePicker
  },
  mixins: [churnReasons],
  props: {
    /**
     * @model
     */
    value: {
      type: Boolean,
      default: false
    },
    /**
     * Retailer name
     */
    retailerName: {
      type: String,
      default: ''
    },
    /**
     * Retailer's CNPJ
     */
    retailerCnpj: {
      type: String,
      default: ''
    },
    /**
     * Retailer id
     */
    client_id: {
      type: String,
      default: ''
    },
    /**
     * For viewing only
     */
    readonly: {
      type: Boolean,
      default: false
    },
    /**
     * Churn data
     */
    dataChurn: {
      type: Object,
      default: null
    },
    /**
     * Is Retailer
     */
    isRetailer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: this.value,
      searchTerm: '',
      billed_client: false,
      inactivated_at: DateTime.now().toISODate(),
      reasonSelected: '',
      phase_selected: '',
      detail_reason: '',
      text_area_rules: [v => (v || '').length <= 240 || 'Detalhe só pode receber até 240 characteres ou menos'],
      responsible_area_selected: ''
    }
  },
  computed: {
    disableButton() {
      return this.phase_selected === '' || this.causeSelected === ''
    },
    reason() {
      const filteredItem = this.churnReasons.find(item => item.data.includes(this.causeSelected))
      return filteredItem ? filteredItem.value : ''
    },
    title() {
      return this.readonly ? 'Dados de churn' : 'Cadastro de Churn'
    }
  },
  watch: {
    value() {
      if (this.value) {
        this.inactivated_at = this.dataChurn === null ? this.inactivated_at : this.$moment(this.dataChurn.inactivated_at).format('YYYY-MM-DD')
        this.billed_client = this.dataChurn === null ? this.billed_client : this.dataChurn.billed_client
      }
      this.dialog = this.value
    },
    dialog() {
      /**
       * Atualiza a model
       * @event input
       */
      if (!this.dialog) {
        this.inactivated_at = DateTime.now().toISODate()
      }
      this.$emit('input', this.dialog)
    }
  },
  methods: {
    getAreas(isPhase = false) {
      const result = ['Comercial', 'CX', 'CS', 'Parcerias', 'Financeiro']
      if (this.isRetailer & !isPhase) result.push('Indústria')
      return result
    },
    /**
     * Dates allowed in calendar display
     * @param {*} val
     * @return {Bollean}
     */
    allowedDates(val) {
      return allowedDateCrmActions(val)
    },
    /**
     * Function to close the modal
     */
    closeDialog() {
      this.dialog = false
    },
    /**
     * Button click function
     */
    clickButton() {
      this.$emit('click', {
        client_id: this.client_id,
        cause: this.causeSelected,
        reason: this.reason,
        details: this.detail_reason,
        phase: this.phase_selected,
        inactivated_at: this.inactivated_at,
        billed_client: this.billed_client,
        responsible_area: this.responsible_area_selected
      })
    },
    /**
     * Method to start the tour
     */
    startTour() {
      const tour = this.$shepherd({
        useModalOverlay: true
      })
      tour.addStep({
        title: 'Dados',
        text: 'Estes campos são referentes a informação do nome e CNPJ',
        attachTo: { element: '#first-section', on: 'bottom' },
        cancelIcon: { enabled: true },
        buttons: [
          {
            text: 'Próximo',
            action: tour.next,
            classes: 'primary'
          }
        ]
      })
      tour.addStep({
        title: 'Data de Cadastro do Churn',
        text: 'Este campo define a data de cadastro do churn. Por padrão ele vem com a data atual.',
        attachTo: { element: '#sixth-section', on: 'bottom' },
        cancelIcon: { enabled: true },
        buttons: [
          {
            text: 'Próximo',
            action: tour.next,
            classes: 'primary'
          }
        ]
      })
      tour.addStep({
        title: 'Cliente faturado',
        text: 'Este campo serve para sinalizar se o cliente já foi faturado',
        cancelIcon: { enabled: true },
        attachTo: { element: '#seven-section', on: 'bottom' },
        buttons: [
          {
            text: 'Anterior',
            action: tour.back
          },
          {
            text: 'Próximo',
            action: tour.next,
            classes: 'primary'
          }
        ]
      })
      tour.addStep({
        title: 'Fase',
        text: 'Este campo serve para indicar a fase que o cliente virou um churn',
        cancelIcon: { enabled: true },
        attachTo: { element: '#second-section', on: 'bottom' },
        buttons: [
          {
            text: 'Anterior',
            action: tour.back
          },
          {
            text: 'Próximo',
            action: tour.next
          }
        ]
      })
      tour.addStep({
        title: 'Causa',
        text: 'Campo para selecionar causa que fez o churn ocorrer',
        cancelIcon: { enabled: true },
        attachTo: { element: '#third-section', on: 'bottom' },
        buttons: [
          {
            text: 'Anterior',
            action: tour.back
          },
          {
            text: 'Próximo',
            action: tour.next
          }
        ]
      })
      tour.addStep({
        title: 'Motivo',
        text: 'Este campo é preenchido automaticamente com base na opção selecionada em causa',
        cancelIcon: { enabled: true },
        attachTo: { element: '#fourth-section', on: 'bottom' },
        buttons: [
          {
            text: 'Anterior',
            action: tour.back
          },
          {
            text: 'Próximo',
            action: tour.next
          }
        ]
      })
      tour.addStep({
        title: 'Área Responsável',
        text: 'Este campo serve para indicar qual foi a fase responsável pelo churn.',
        cancelIcon: { enabled: true },
        attachTo: { element: '#fifth-section', on: 'bottom' },
        buttons: [
          {
            text: 'Anterior',
            action: tour.back
          },
          {
            text: 'Próximo',
            action: tour.next
          }
        ]
      })
      tour.addStep({
        title: 'Detalhar Motivo',
        text: 'Este campo serve para adicionar mais dados que levaram ao churn ',
        cancelIcon: { enabled: true },
        attachTo: { element: '#seventh-section', on: 'bottom' },
        buttons: [
          {
            text: 'Anterior',
            action: tour.back
          },
          {
            text: 'Completar',
            action: tour.complete
          }
        ]
      })
      tour.start()
    },
    translatePhase(phase) {
      switch (phase) {
        case 'comercial':
          return 'Comercial'
        case 'cx':
          return 'CX'
        case 'cs':
          return 'CS'
        case 'partnerships':
          return 'Parcerias'
        case 'financial':
          return 'Financeiro'
        case 'industry':
          return 'Indústria'
      }
    }
  }
}
</script>
