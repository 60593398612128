import { ChurnReasons } from '@/constants'
export const churnReasons = {
  data: () => ({
    causeSelected: ''
  }),
  computed: {
    churnReasons() {
      return ChurnReasons
    },
    listCauses() {
      return this.churnReasons.reduce((accumulator, currentItem) => {
        return accumulator.concat(currentItem.data)
      }, [])
    },
    reason() {
      const filteredItem = this.churnReasons.find(item => item.data.includes(this.causeSelected))
      return filteredItem ? filteredItem.value : ''
    }
  }
}
